<template>
    <v-card flat class="ma-0 pa-0 replies">
        <v-card-actions
            v-if="replies && replies.length > 0"
            class="ma-0 pa-0 mb-2"
        >
            <v-btn depressed @click="showReplies = !showReplies">
                {{ $t("complaints.replies.replies") }} ({{ replies.length }})
                <v-icon>
                    {{
                        showReplies ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon
                >
            </v-btn>
        </v-card-actions>

        <v-expand-transition v-if="replies.length > 0">
            <div v-show="showReplies">
                <Reply
                    class="mb-2"
                    v-for="(reply, index) in replies"
                    :key="index"
                    :reply="reply"
                />
            </div>
        </v-expand-transition>
        <Reply
            :key="replies.length"
            v-if="!showReplies"
            :reply="replies[replies.length - 1]"
        />

        <v-textarea v-if="isInRole(roles.complaint.reply)"
            class="mt-3"
            :disabled="complaintStatus == 3"
            outlined
            v-model="reply.message"
            prepend-inner-icon="send"
            @keyup="replyKeyDown"
            @click:prepend-inner="send"
            no-details
            :label="$t('complaints.replies.reply')"
            hide-details
            :rows="3"
        >
            <template v-slot:append-outer>
                <v-checkbox
                    v-model="sendOnPressEnter"
                    hide-details
                    :title="$t('complaints.replies.pressEnterToSend')"
                ></v-checkbox>
            </template>
        </v-textarea>
        <v-file-input v-if="isInRole(roles.complaint.reply)"
            :disabled="complaintStatus == 3"
            :label="$t('complaints.replies.attachments')"
            v-model="currFiles"
            small-chips
            show-size
            multiple
            hide-details
            @click:clear="clearFiles"
            @change="inputChanged"
        >
            <template v-slot:selection="{ text }">
                <v-chip small text-color="white" color="primary">
                    {{ text }}
                </v-chip>
            </template>
        </v-file-input>
    </v-card>
</template>
<script>
import axios from "axios";
import Reply from "./Reply.vue";
export default {
    components: { Reply },
    props: [
        "replies",
        "complaintGuid",
        "refreshComplaint",
        "employeeName",
        "complaintStatus"
    ],
    data() {
        return {
            loading: false,
            showReplies: false,
            sendOnPressEnter: false,
            valid: true,
            replyFiles: [],
            files: [],
            currFiles: [],
            reply: {
                message: null,
                files: []
            },
            rules: [value => !!value]
        };
    },
    methods: {
        clearFiles() {
            try {
                this.files = [];
            } catch (error) {
                console.log(error);
            }
        },
        inputChanged() {
            this.files = [...this.currFiles, ...this.files];
        },
        replyKeyDown(e) {
            if (this.sendOnPressEnter && e.key == "Enter") {
                this.send();
            }
        },
        send() {
            try {
                if (this.reply.message != null && this.reply.message != "") {
                    var data = new FormData();
                    var validFiles = true;

                    for (let i = 0; i < this.files.length; i++) {
                        var file = this.files[i];
                        if (file != null && file.size <= 20971520) {
                            data.append("files", file);
                        } else {
                            validFiles = false;
                            break;
                        }
                    }

                    if (!validFiles) {
                        this.$toast.error(this.$t("error.MaxFileSizeReached"));
                        return;
                    }

                    data.append("message", this.reply.message);
                    data.append("complaintGuid", this.complaintGuid);

                    this.loading = true;
                    axios
                        .post("Complaints/ReplyComplaint", data)
                        .then(response => {
                            if (
                                response.data.status == this.responseStatus.ok
                            ) {
                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );
                                this.refreshComplaint();
                                this.files = [];
                                this.currFiles = [];
                                this.reply.message = null;
                            } else {
                                this.$toast.error(
                                    this.$t("error." + response.data.message)
                                );
                            }
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            console.log(e);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
};
</script>
<style>
.replies,
.v-card__text {
    padding: 8px !important;
}
</style>
