<template>
  <v-container fluid
    ><v-card :loading="loading" class="pb-1">
      <Page-Header
        :title="$t('preview') + ' ' + $t('complaints.complaint')"
        icon="mdi-card-text"
      >
        <template v-slot:buttons>
          <v-card-actions>
            <v-autocomplete hide-details class="mx-3" 
              v-if="isHandledBySameUser && isInRole(25)"
              :items="complaintStatus"
              item-text="text"
              item-value="value"
              dense
              v-model="complaintState"
              :label="$t('complaints.complaintStatus')"
              outlined
              prepend-icon="mdi-content-save-outline"
              @click:prepend="changeState"
            >
            </v-autocomplete>

            <v-btn
              v-if="isInRole(25)"
              :disabled="isHandled && !isHandledBySameUser"
              :color="
                isHandled
                  ? 'red darken-4 white--text'
                  : 'primary darken-1 white--text'
              "
              @click="openAssignDialog"
              :min-width="100"
            >
              <v-icon>{{
                isHandled
                  ? "mdi-account-cancel-outline"
                  : "mdi-account-arrow-left-outline"
              }}</v-icon>
              {{
                isHandled
                  ? $t("complaints.ui.cancelHandling")
                  : $t("complaints.ui.takeHandling")
              }}
            </v-btn>
          </v-card-actions>
        </template>
      </Page-Header>
      <v-system-bar
        v-if="isHandled && !isHandledBySameUser"
        height="30"
        color="yellow darken-3"
        window
        ><span>{{ $t("complaints.warnings.alreadyHandled") + ": " }}</span>
        <span>{{
          isHandled && !isHandledBySameUser
            ? editedItem.userHandler.username
            : null
        }}</span>
      </v-system-bar>
      <v-card  class="ma-5 " outlined v-if="editedItem && editedItem.employee">
        <v-list-item>
          <v-list-item-avatar v-if="editedItem.employee && editedItem.employee.employeeName"
            :color="getColorFromName(editedItem.employee.employeeName)"
            class="pb-1 white--text"
          >
            {{ getShortName(editedItem.employee.employeeName) }}
          </v-list-item-avatar>
          <v-list-item-content class="pa-0">
            <v-row no-gutters>
              <v-col class="mt-3">
                {{ editedItem.employee.employeeName }}
              </v-col>
              <v-col align="center" class="mt-3">
                <v-list-item-subtitle
                  :title="
                    editedItem.complaintDate | moment('YYYY/MM/DD  hh:mm A')
                  "
                >
                  {{ editedItem.complaintDate2 }}</v-list-item-subtitle
                >
              </v-col>
              <v-col align="end" class="mt-3">
                <p class="text--secondary">
                  {{ $t("complaints.complaintTo") + ": " }}
                  {{
                    editedItem.complaintTo != undefined &&
                    editedItem.complaintTo.employeeName != null
                      ? editedItem.complaintTo.employeeName
                      : $t("complaints.toWhomItMayConcern")
                  }}
                </p>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <!-- <v-list-item-icon >
            <v-list-item-subtitle
              >{{ $t("complaints.subject") }}:
            </v-list-item-subtitle>
          </v-list-item-icon> -->

          <v-list-item-content class="pa-0">
            <v-list-item-title class="text-h6 text-wrap">
              {{ editedItem.subject }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <!-- <v-list-item-icon >
            <v-list-item-subtitle
              >{{ $t("complaints.details") }}:
            </v-list-item-subtitle>
          </v-list-item-icon> -->

          <v-list-item-content class="pa-0">
            {{ editedItem.details }}
          </v-list-item-content>
        </v-list-item>

        <v-card-actions v-if="complaintFiles.length > 0">
          <v-btn depressed @click="showAttachments = !showAttachments">
            {{ $t("complaints.complaintAttachments") }} ({{
              complaintFiles.length
            }})
            <v-icon>
              {{
                showAttachments ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon
            >
          </v-btn>
        </v-card-actions>

        <v-expand-transition v-if="complaintFiles.length > 0">
          <div v-show="showAttachments">
            <v-divider></v-divider>

            <v-card-text>
              <v-row class="ma-2">
                <v-col
                  cols="auto"
                  v-for="file in complaintFiles"
                  :key="file.name"
                >
                  <Download-File
                    :iconWidth="50"
                    :fileSizeFormat="file.size"
                    :allowDelete="false"
                    :file="file"
                    :fileUrl="file.path"
                    :width="250"
                  ></Download-File>
                </v-col>
              </v-row>
            </v-card-text>
          </div>
        </v-expand-transition>

        <v-divider></v-divider>

        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" class="ma-0 pa-0"
              ><Replies 
                :refreshComplaint="setComplaint"
                :complaintGuid="editedItem.complaintGuid"
                :replies="editedItem.replies"
                :employeeName="
                  editedItem.complaintTo != undefined &&
                  editedItem.complaintTo.employeeName != null
                    ? editedItem.complaintTo.employeeName
                    : $t('complaints.toWhomItMayConcern')
                "
                :complaintStatus="editedItem.state"
                :key="editedItem.state"
            /></v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <Confirm-Dialog
        :openDialog="confirmAssignmentDialog"
        :onClicked="changeHandler"
        :onClose="closeAssignDialog"
        toolBarColor="red darken-2"
        :dialogTitle="' '"
      ></Confirm-Dialog> </v-card
  ></v-container>
</template>
<script>
import axios from "axios";
import DownloadFile from "../../components/downloadFile.vue";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";
import Replies from "../Complaints/Replies.vue";
import moment from "moment";
export default {
  components: {
    DownloadFile,
    ConfirmDialog,
    PageHeader,
    Replies,
  },
  watch: {
    dialog(val) {
      val || this.closeDialog();
    },
  },
  created() {
    try {
      /*  if (
                this.editedItem.complaintGuid != undefined &&
                this.editedItem.complaintGuid != null &&
                this.editedItem.complaintGuid !=
                    "00000000-0000-0000-0000-000000000000" &&
                this.editedItem.complaintGuid != ""
            )
                this.setComplaint(this.editedItem.complaintGuid); */

      this.setComplaint();
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      complaintGuid: this.$route.params.id,
      editedItem: {},
      showAttachments: false,
      loading: false,
      confirmAssignmentDialog: false,
      complaintFiles: [],
      files: [],
      isHandled: false,
      isHandledBySameUser: false,
      userGuid: localStorage.getItem("userId"),
      complaintState: 0,
      complaintStatus: [
        {
          text: this.$t("complaints.types.new"),
          value: 0,
        },
        {
          text: this.$t("complaints.types.open"),
          value: 1,
        },
        {
          text: this.$t("complaints.types.pending"),
          value: 2,
        },
        {
          text: this.$t("complaints.types.closed"),
          value: 3,
        },
        {
          text: this.$t("complaints.types.spam"),
          value: 4,
        },
      ],
      rules: [(value) => !!value || this.$t("required")],
    };
  },
  methods: {
    setComplaint() {
      try {
        axios
          .get("Complaints/Get", {
            params: {
              complaintGuid: this.complaintGuid,
            },
          })
          .then((response) => {
            this.editedItem = response.data.data;
            this.complaintFiles = response.data.data.files;
            this.complaintState = response.data.data.state;
            this.isHandled =
              response.data.data.userHandler != undefined &&
              response.data.data.userHandler.userGuid != null;
            this.isHandledBySameUser =
              response.data.data.userHandler != undefined &&
              response.data.data.userHandler.userGuid != null &&
              response.data.data.userHandler.userGuid == this.userGuid;

            this.editedItem.complaintDate2 = moment(
              this.editedItem.complaintDate
            ).fromNow();
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
      }
    },
    changeHandler() {
      try {
        axios
          .put(
            `Complaints/ComplaintAssignment?complaintGuid=${this.editedItem.complaintGuid}`
          )
          .then((response) => {
            if (response.data.status == "Successful") {
              this.$toast.success(this.$t("operationAccomplishedSuccessfully"));
              this.setComplaint();
            } else {
              this.$toast.error(this.$t("error." + response.data.message));
            }
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            this.loading = false;
            this.closeAssignDialog();
          });
      } catch (error) {
        console.log(error);
      }
    },
    closeDialog() {
      this.close();
    },
    clearFiles() {
      try {
        this.files = [];
      } catch (error) {
        console.log(error);
      }
    },
    changeState() {
      try {
        if (this.complaintState == this.editedItem.state) {
          this.$toast.warning(this.$t("error.ComplaintAlreadyInThisState"));
          return;
        }
        axios
          .put("Complaints/ChangeState", {
            complaintGuid: this.editedItem.complaintGuid,
            state: this.complaintState,
          })
          .then((response) => {
            if (response.data.status == "Successful") {
              this.$toast.success(this.$t("operationAccomplishedSuccessfully"));
              //this.refreshData();
            } else {
              this.$toast.error(this.$t("error." + response.data.message));
            }
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
      }
    },
    closeAssignDialog() {
      this.confirmAssignmentDialog = false;
    },
    openAssignDialog() {
      this.confirmAssignmentDialog = true;
    },

    getColorFromName(name) {
        if(!name ||name == null || name == undefined || name == "" ||name.length == 0){
            return "primary";
        }
      return this.stringToHslColor(name, 40, 50);
    },
    getShortName(name) {
        if(!name || name == null || name == undefined || name == "" ||name.length == 0){
            return "";
        }
      return name
        .split(" ")
        .slice(0, 2)
        .map((str) => (str ? str[0].toUpperCase() : ""))
        .join(".");
    },
    stringToHslColor(str, s, l) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      var h = hash % 360;
      return "hsl(" + h + ", " + s + "%, " + l + "%)";
    },
  },
};
</script>
<style >
.v-application .text-h6 {
  font-family: inherit !important;
}
</style>