<template>
    <v-card outlined v-if="reply != undefined">
        <v-list-item>
            <v-list-item-avatar
                class="white--text"
                :color="
                    getColorFromName(
                        reply.employeeName == null
                            ? reply.userName
                            : reply.employeeName
                    )
                "
            >
                {{
                    getShortName(
                        reply.employeeName == null
                            ? reply.userName
                            : reply.employeeName
                    )
                }}
            </v-list-item-avatar>
            <v-list-item-content class="pa-0">
                <v-row no-gutters>
                    <v-col class="mt-1">
                        <v-list-item-subtitle>
                            {{
                                reply.employeeName == null
                                    ? reply.userName
                                    : reply.employeeName
                            }}
                        </v-list-item-subtitle>
                    </v-col>
                    <v-col align="end" class="mt-3">
                        <v-list-item-subtitle
                            :title="
                                reply.replyDate | moment('YYYY/MM/DD hh:mm A')
                            "
                        >
                            {{ reply.replyDate2 }}</v-list-item-subtitle
                        >
                    </v-col>
                </v-row>
            </v-list-item-content>
        </v-list-item>
        <v-list-item>
            <v-list-item-content
                style="white-space: pre-line !important"
                class="pa-0 text-justify"
            >
                {{ reply.message }}
            </v-list-item-content>
        </v-list-item>
        <!-- Reply Attachment Files -->

        <v-card-actions v-if="reply.files && reply.files.length > 0">
            <v-btn depressed @click="showAttachments = !showAttachments">
                {{ $t("complaints.complaintAttachments") }} ({{
                    reply.files.length
                }})
                <v-icon>
                    {{
                        showAttachments ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon
                >
            </v-btn>
        </v-card-actions>

        <v-expand-transition v-if="reply.files && reply.files.length > 0">
            <div v-show="showAttachments">
                <v-divider></v-divider>

                <v-card-text>
                    <v-row
                        class="ma-2"
                        :dir="reply.leftOrRight ? 'rtl' : 'ltr'"
                    >
                        <v-col
                            cols="auto"
                            v-for="file in reply.files"
                            :key="file.name"
                        >
                            <Download-File
                                :iconWidth="50"
                                :fileSizeFormat="file.size"
                                :allowDelete="false"
                                :file="file"
                                :fileUrl="file.path"
                                :width="250"
                            ></Download-File>
                        </v-col>
                    </v-row>
                </v-card-text>
            </div>
        </v-expand-transition>
    </v-card>
</template>
<script>
import DownloadFile from "../../components/downloadFile.vue";
import moment from "moment";

export default {
    components: { DownloadFile },
    props: ["reply"],
    data() {
        return { showAttachments: false };
    },
    created() {
        if (this.reply)
            this.reply.replyDate2 = moment(this.reply.replyDate).fromNow();
    },
    computed: {
        userName() {
            return localStorage.fullname;
        }
    },
    methods: {
        getColorFromName(name) {
            if (!name) return "primary";
            return this.stringToHslColor(name, 40, 50);
        },
        getShortName(name) {
            if (!name) return;
            return name
                .split(" ")
                .slice(0, 2)
                .map(str => (str ? str[0].toUpperCase() : ""))
                .join(".");
        },
        stringToHslColor(str, s, l) {
            var hash = 0;
            if (!str) return;
            for (var i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
            var h = hash % 360;
            return "hsl(" + h + ", " + s + "%, " + l + "%)";
        }
    }
};
</script>
